<template>
	<v-icon v-bind="$attrs" v-on="$listeners">
		<slot></slot>
	</v-icon>
</template>

<script>
/**
 * @displayName w-icon
 *
 * @since 0.3.2
 */
export default {
	name: 'WIcon'
}
</script>
